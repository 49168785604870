/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const PasswordState = {
    Unset: 'Unset',
    Set: 'Set',
    MultipleSet: 'MultipleSet'
};
export function instanceOfPasswordState(value) {
    for (const key in PasswordState) {
        if (Object.prototype.hasOwnProperty.call(PasswordState, key)) {
            if (PasswordState[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function PasswordStateFromJSON(json) {
    return PasswordStateFromJSONTyped(json, false);
}
export function PasswordStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PasswordStateToJSON(value) {
    return value;
}
