/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AuthStateResponseInternalFromJSON, ChangePasswordRequestToJSON, CredentialsStateFromJSON, ExistingOtpCredentialFromJSON, ExistingPublicKeyCredentialFromJSON, InfoFromJSON, LoginRequestToJSON, NewOtpCredentialToJSON, NewPublicKeyCredentialToJSON, OtpLoginRequestToJSON, PasswordStateFromJSON, SsoProviderDescriptionFromJSON, StartSloResponseParamsFromJSON, StartSsoResponseParamsFromJSON, TargetSnapshotFromJSON, } from '../models/index';
/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
    /**
     */
    async addMyOtpRaw(requestParameters, initOverrides) {
        if (requestParameters['newOtpCredential'] == null) {
            throw new runtime.RequiredError('newOtpCredential', 'Required parameter "newOtpCredential" was null or undefined when calling addMyOtp().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        const response = await this.request({
            path: `/profile/credentials/otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewOtpCredentialToJSON(requestParameters['newOtpCredential']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExistingOtpCredentialFromJSON(jsonValue));
    }
    /**
     */
    async addMyOtp(requestParameters, initOverrides) {
        const response = await this.addMyOtpRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async addMyPublicKeyRaw(requestParameters, initOverrides) {
        if (requestParameters['newPublicKeyCredential'] == null) {
            throw new runtime.RequiredError('newPublicKeyCredential', 'Required parameter "newPublicKeyCredential" was null or undefined when calling addMyPublicKey().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        const response = await this.request({
            path: `/profile/credentials/public-keys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPublicKeyCredentialToJSON(requestParameters['newPublicKeyCredential']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExistingPublicKeyCredentialFromJSON(jsonValue));
    }
    /**
     */
    async addMyPublicKey(requestParameters, initOverrides) {
        const response = await this.addMyPublicKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async approveAuthRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling approveAuth().');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/state/{id}/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthStateResponseInternalFromJSON(jsonValue));
    }
    /**
     */
    async approveAuth(requestParameters, initOverrides) {
        const response = await this.approveAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async cancelDefaultAuthRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/state`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthStateResponseInternalFromJSON(jsonValue));
    }
    /**
     */
    async cancelDefaultAuth(initOverrides) {
        const response = await this.cancelDefaultAuthRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async changeMyPasswordRaw(requestParameters, initOverrides) {
        if (requestParameters['changePasswordRequest'] == null) {
            throw new runtime.RequiredError('changePasswordRequest', 'Required parameter "changePasswordRequest" was null or undefined when calling changeMyPassword().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        const response = await this.request({
            path: `/profile/credentials/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestToJSON(requestParameters['changePasswordRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordStateFromJSON(jsonValue));
    }
    /**
     */
    async changeMyPassword(requestParameters, initOverrides) {
        const response = await this.changeMyPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async deleteMyOtpRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteMyOtp().');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/profile/credentials/otp/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteMyOtp(requestParameters, initOverrides) {
        await this.deleteMyOtpRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deleteMyPublicKeyRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteMyPublicKey().');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/profile/credentials/public-keys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteMyPublicKey(requestParameters, initOverrides) {
        await this.deleteMyPublicKeyRaw(requestParameters, initOverrides);
    }
    /**
     */
    async getAuthStateRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getAuthState().');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/state/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthStateResponseInternalFromJSON(jsonValue));
    }
    /**
     */
    async getAuthState(requestParameters, initOverrides) {
        const response = await this.getAuthStateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getDefaultAuthStateRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/state`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthStateResponseInternalFromJSON(jsonValue));
    }
    /**
     */
    async getDefaultAuthState(initOverrides) {
        const response = await this.getDefaultAuthStateRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getInfoRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => InfoFromJSON(jsonValue));
    }
    /**
     */
    async getInfo(initOverrides) {
        const response = await this.getInfoRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getMyCredentialsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/profile/credentials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CredentialsStateFromJSON(jsonValue));
    }
    /**
     */
    async getMyCredentials(initOverrides) {
        const response = await this.getMyCredentialsRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getSsoProvidersRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/sso/providers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SsoProviderDescriptionFromJSON));
    }
    /**
     */
    async getSsoProviders(initOverrides) {
        const response = await this.getSsoProvidersRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getTargetsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/targets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TargetSnapshotFromJSON));
    }
    /**
     */
    async getTargets(requestParameters = {}, initOverrides) {
        const response = await this.getTargetsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async initiateSsoLogoutRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/sso/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StartSloResponseParamsFromJSON(jsonValue));
    }
    /**
     */
    async initiateSsoLogout(initOverrides) {
        const response = await this.initiateSsoLogoutRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async loginRaw(requestParameters, initOverrides) {
        if (requestParameters['loginRequest'] == null) {
            throw new runtime.RequiredError('loginRequest', 'Required parameter "loginRequest" was null or undefined when calling login().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters['loginRequest']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async login(requestParameters, initOverrides) {
        await this.loginRaw(requestParameters, initOverrides);
    }
    /**
     */
    async logoutRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async logout(initOverrides) {
        await this.logoutRaw(initOverrides);
    }
    /**
     */
    async otpLoginRaw(requestParameters, initOverrides) {
        if (requestParameters['otpLoginRequest'] == null) {
            throw new runtime.RequiredError('otpLoginRequest', 'Required parameter "otpLoginRequest" was null or undefined when calling otpLogin().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        const response = await this.request({
            path: `/auth/otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OtpLoginRequestToJSON(requestParameters['otpLoginRequest']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async otpLogin(requestParameters, initOverrides) {
        await this.otpLoginRaw(requestParameters, initOverrides);
    }
    /**
     */
    async rejectAuthRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling rejectAuth().');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/auth/state/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthStateResponseInternalFromJSON(jsonValue));
    }
    /**
     */
    async rejectAuth(requestParameters, initOverrides) {
        const response = await this.rejectAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async returnToSsoRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['code'] != null) {
            queryParameters['code'] = requestParameters['code'];
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/sso/return`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async returnToSso(requestParameters = {}, initOverrides) {
        await this.returnToSsoRaw(requestParameters, initOverrides);
    }
    /**
     */
    async returnToSsoWithFormDataRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/sso/return`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse(response);
        }
        else {
            return new runtime.TextApiResponse(response);
        }
    }
    /**
     */
    async returnToSsoWithFormData(initOverrides) {
        const response = await this.returnToSsoWithFormDataRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async startSsoRaw(requestParameters, initOverrides) {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError('name', 'Required parameter "name" was null or undefined when calling startSso().');
        }
        const queryParameters = {};
        if (requestParameters['next'] != null) {
            queryParameters['next'] = requestParameters['next'];
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/sso/providers/{name}/start`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StartSsoResponseParamsFromJSON(jsonValue));
    }
    /**
     */
    async startSso(requestParameters, initOverrides) {
        const response = await this.startSsoRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async stubRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // AnySecurityScheme authentication
        }
        const response = await this.request({
            path: `/__stub__`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async stub(initOverrides) {
        await this.stubRaw(initOverrides);
    }
}
