/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExistingSsoCredentialFromJSON, ExistingSsoCredentialToJSON, } from './ExistingSsoCredential';
import { ExistingOtpCredentialFromJSON, ExistingOtpCredentialToJSON, } from './ExistingOtpCredential';
import { PasswordStateFromJSON, PasswordStateToJSON, } from './PasswordState';
import { UserRequireCredentialsPolicyFromJSON, UserRequireCredentialsPolicyToJSON, } from './UserRequireCredentialsPolicy';
import { ExistingPublicKeyCredentialFromJSON, ExistingPublicKeyCredentialToJSON, } from './ExistingPublicKeyCredential';
/**
 * Check if a given object implements the CredentialsState interface.
 */
export function instanceOfCredentialsState(value) {
    if (!('password' in value) || value['password'] === undefined)
        return false;
    if (!('otp' in value) || value['otp'] === undefined)
        return false;
    if (!('publicKeys' in value) || value['publicKeys'] === undefined)
        return false;
    if (!('sso' in value) || value['sso'] === undefined)
        return false;
    if (!('credentialPolicy' in value) || value['credentialPolicy'] === undefined)
        return false;
    return true;
}
export function CredentialsStateFromJSON(json) {
    return CredentialsStateFromJSONTyped(json, false);
}
export function CredentialsStateFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'password': PasswordStateFromJSON(json['password']),
        'otp': (json['otp'].map(ExistingOtpCredentialFromJSON)),
        'publicKeys': (json['public_keys'].map(ExistingPublicKeyCredentialFromJSON)),
        'sso': (json['sso'].map(ExistingSsoCredentialFromJSON)),
        'credentialPolicy': UserRequireCredentialsPolicyFromJSON(json['credential_policy']),
    };
}
export function CredentialsStateToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'password': PasswordStateToJSON(value['password']),
        'otp': (value['otp'].map(ExistingOtpCredentialToJSON)),
        'public_keys': (value['publicKeys'].map(ExistingPublicKeyCredentialToJSON)),
        'sso': (value['sso'].map(ExistingSsoCredentialToJSON)),
        'credential_policy': UserRequireCredentialsPolicyToJSON(value['credentialPolicy']),
    };
}
